<template>
  <div>
    <!-- Your content here -->
    <b-button
        class="floating-button btn btn-info"
        variant="primary"
        size="lg"
        @click="refresh()"
    >
      <i class="uil-refresh"></i>
    </b-button>
  </div>
</template>

<script>


export default {
  name: "FloatButton",

  methods: {
    refresh() {
      this.$emit("refresh-clicked");
    }
  }
}
</script>

<style scoped>
.floating-button {
  position: fixed; /* Or absolute if you want it relative to the parent */
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 1000; /* Ensure it's above other content */
}
</style>