<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <h6>Seller</h6>
            <div class="col-md-3">
              <label>Select State </label>
              <multiselect
                  v-model="state"
                  :options="stateArr"
                  :show-labels="false"
                  label="state"
                  track-by="stateID"
              ></multiselect>
            </div>

            <div class="col-md-3">
              <label>Mode/Term Of Payment</label>
              <input
                  v-model="paymentMode"
                  class="form-control"
                  type="text"
              />
            </div>
            <div class="col-md-3">
              <label class="form-label" >Sez Supply </label>
              <multiselect v-model="sezSupply" :options="sezArr" :show-labels="false"></multiselect>
            </div>
            <div class="col-md-3">
              <label class="form-label" >Sell For </label>
              <multiselect v-model="saleFor" :options="saleArr" :show-labels="false"></multiselect>
            </div>
</div>
          <div class="row mt-2" v-if="state">
            <h5>CLOUDKITCH PRIVATE LIMITED</h5>
            <address>
              {{state.cloudKitchBillingAddress}}<br>
              <b>GSTIN/UIN</b> : {{state.cloudKitchGstNo}}<br>
              <b>PAN No.</b> : {{state.panNo}}<br>
              <b>State Name</b> : {{state.state}}, Code : {{state.shortCode}}<br>
              <b>CIN</b> : {{state.cinNo}}<br>
              <b>FSS LIC NO</b> :{{fssLicNo}}<br>
              <b>Contact</b> - {{state.ckPhoneNo}}  <b>Email ID</b> - {{state.ckEmailID}}
            </address>
          </div>
          <div class="row mt-3">
          <h6>Buyer</h6>
            <div
                class="col-md-2"

            >
              <label class="form-label"
              >Select Country
              </label>
              <multiselect
                  v-model="country"
                  :options="countryArr"
                  :show-labels="false"
                  label="country"
                  track-by="country"
                  @input="getAllCities()"
              ></multiselect>
            </div>
            <div
                class="col-md-2"

            >
              <label >Select City </label>
              <multiselect
                  v-model="city"
                  :options="cityArr"
                  :show-labels="false"
                  label="city"
                  track-by="city"
                  @input="getCorporateBranches(),getAllRestaurantBranches()"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label class="form-label">Select Corporate Branch </label>
              <multiselect
                  v-model="corpBranchID"
                  :options="corpBranches"
                  :show-labels="false"
                  label="corpBranchName"
                  track-by="corpBranchID"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>
            <div class="col-md-4">
              <label class="form-label" for="form row-endTime-input"
              >Select Restaurant Branch*</label
              >
              <multiselect
                  v-model="restBranchID"
                  :options="restBranchArr"
                  label="restaurantName"
                  track-by="restBranchID"
              ></multiselect>
            </div>
            </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <label>Buyer's Order No</label>
              <input
                  v-model="orderNo"
                  class="form-control"
                  type="text"
              />
            </div>
            <div class="col-md-6 offset-md-2">
              <div class="row">
                <div class="col-md-12">
                  <label>Destination</label>
                  <input
                      v-model="destination"
                      class="form-control"
                      type="text"
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <label>Date Of Service</label>
                  <br>
                  <input
                      id="formrow-startDate-input"
                      v-model="daterange"
                      class="form-control"
                      type="date"
                  />
<!--                  <date-picker-->
<!--                      v-model="daterange"-->
<!--                      append-to-body-->
<!--                      confirm-->
<!--                      format="DD MMM Y"-->
<!--                      lang="en"-->
<!--                      value-type="format"-->
<!--                      range-->
<!--                  ></date-picker>-->
                </div>
              </div>
            </div>

            <div class="col-md-8">
              <label>Terms of Delivery</label>
              <input
                  v-model="termOfDelivery"
                  class="form-control"
                  type="text"
                  readonly
              />
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-6" v-if="corpBranchID">
              <h5>{{corpBranchID.corpBranchName}}</h5>
              <address>
                {{corpBranchID.address}}<br>
                <b>GSTIN/UIN</b> : {{corpBranchID.gstNo}}<br>
                <b>PAN No.</b> : {{corpBranchID.panNo}}<br>
                <b>CIN</b> : {{corpBranchID.cinNo}}<br>
                <b>Contact</b> - {{corpBranchID.phoneNo}}
              </address>
            </div>
        <div class="col-md-6" v-if="restBranchID">
          <h5>{{restBranchID.restaurantName}}</h5>
          <address>
            {{restBranchID.address}}<br>
            <b>Vendor FSS LIC No.</b> : {{restBranchID.licenseNO}}<br>
          </address>
          </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 ">
      <div class="card">
        <div class="card-body">
          <h6 v-if="!isEdit">Add Services</h6>
          <div class="inner-repeater mb-4">
            <div class="inner mb-3">
              <div
                  v-for="(data, index) in taskArr"
                  :key="data.id"
                  class="inner mb-3 row no-gutter"
              >
                <div class="col-md-3">
                  <label class="form-label">Description of Service: *</label>
                  <input
                      v-model="data.service_name"
                      class="form-control"
                      type="text"
                      :title="data.service_name"
                  />

                </div>
                <div class="col-md-1" style="width:100px;">
                  <label class="form-label">GST Rate</label>
                  <multiselect
                      v-model="data.gstRate"
                      :options="gstRateArr"
                      :show-labels="false"
                      label="gst_percent"
                      track-by="id"
                      :title="data.gstRate"
                      @input="setSacCode(index,'gst')"
                  ></multiselect>
                </div>
                <div class="col-md-1" style="width:100px;">
                  <label class="form-label">IGST Rate</label>
                  <multiselect
                      v-model="data.igstRate"
                      :options="igstRateArr"
                      :show-labels="false"
                      label="igst_percent"
                      track-by="id"
                      :title="data.igstRate"
                      @input="setSacCode(index,'igst')"
                  ></multiselect>
                </div>
                <div class="col-md-1" style="width:100px;">
                  <label class="form-label">SAC</label>
                  <input
                      v-model="data.sac"
                      class="form-control"
                      type="text"
                      readonly
                      :title="data.sac"
                  />
                </div>
                <div class="col-md-2" style="width:100px;">
                  <label class="form-label">Quantity</label>
                  <input
                      v-model="data.quantity"
                      class="form-control"
                      type="number"
                      :title="data.quantity"
                      min="0"
                      @input="calculateAmount(index)"
                  />
                </div>
                <div class="col-md-1" style="width:100px;">
                  <label class="form-label">Rate</label>
                  <input
                      v-model="data.rate"
                      class="form-control"
                      type="text"
                      :title="data.rate"
                      @input="calculateAmount(index)"
                  />
                </div>
                <div class="col-md-1" style="width:140px;">
                  <label class="form-label">Unit</label>
                  <input
                      v-model="data.unit"
                      class="form-control"
                      type="text"
                     :title="data.unit"
                  />
                </div>
                <div class="col-md-2" style="width:115px;">
                  <label class="form-label">Amount</label>
                  <input
                      v-model="data.amount"
                      class="form-control"
                      type="text"
                      :title="data.amount"

                  />
                </div>

                <div class="col-md-1 pt-4 align-self-center d-grid">
                  <input
                      class="btn btn-themeBrown btn-block inner"
                      type="button"
                      value="Delete"
                      @click="deleteItem(index, data.eventItemId)"
                  />
                </div>
              </div>
            </div>
            <input
                class="btn btn-themeOrange inner"
                type="button"
                value="Add New Service"
                @click="AddItem"
            />
          </div>

          <div class=" row" style="float:right;">
            <b-spinner
                v-if="loading"
                class="m-2 col-3"
                role="status"
                variant="primary"
            ></b-spinner>
            <button
                v-if="!isEdit"
                :disabled="loading"
                class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                type="button"
                @click="submitForm()"
            >
              Submit
            </button>
            <button
                v-if="isEdit && $can('update event sale')"
                :disabled="loading"
                class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                type="button"
                @click="updateForm()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <FloatButton @refresh-clicked="refreshPage()"></FloatButton>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
import FloatButton from "@/components/FloatButton.vue";

export default {
  name: "EventSalesForm" ,
  page:{
    title: "Add New Event Sale",
    meta: [
      {
        name: "Add New Event Sale",
        content: appConfig.description
      }
    ]
  },
  components: {FloatButton, Multiselect, Layout,PageHeader},
  data() {
    return {
      title: "Add New Event Sale ",
      items: [
        {
          text: "Add New Event Sale",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],

      isEdit: false,
      isEditadd: false,
      status: "",
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      restBranchArr: [],
      restData: [],
      daterange:"",
      restBranchID: "",
      taskArr: [
        {
          id:1,
          eventItemId:"",
          service_name: "",
          sac: "",
          gstRate: 0,
          igstRate: 0,
          quantity:0,
          rate:0,
          unit:"",
          amount:0,
        }
      ],
      gstRateArr: [],
      igstRateArr: [],
      state:"",
      stateArr:[],
      fssLicNo:11519005000913,
      termOfDelivery:"Intra State Supply",
      paymentMode:"",
      sezArr: [
        "Yes","No"
      ],
      sezSupply:"",
      saleArr: [
        "CloudKitch","Flavourly"
      ],
      saleFor:"",
      destination:"",
      corpBranchID: "",
      corpBranches: [],
      orderNo:"",
      countryArr: [],
      country: "",
      cityArr: [],
      city: "",
      event_sales_id:0,
      // nowDate: new Date(Date.now() + 3600 * 1000 * 24)
      //     .toISOString()
      //     .slice(0, 10)
    };
  },

  mounted() {
    this.getStates();
    this.getAllCountry();
    this.getGSTSlabs();
    this.getIGSTSlabs();
    if(this.$route.params.type=="edit"){
      if(this.$route.params.id){
        sessionStorage.setItem('event',this.$route.params.id);
        sessionStorage.setItem('evt_mod',this.$route.params.type);
      }
    }else{
      sessionStorage.setItem('event','');
      sessionStorage.setItem('evt_mod','');
    }
    if(sessionStorage.getItem('evt_mod')=="edit" && sessionStorage.getItem('event')){
      this.title="Edit Event Service";
      this.editEvent();
    }
  },
  methods: {
    refreshPage(){
      this.getStates();
      this.getAllCountry();
      this.getGSTSlabs();
      this.getIGSTSlabs();
      if(sessionStorage.getItem('evt_mod')=="edit" && sessionStorage.getItem('event')){
        this.title="Edit Event Service";
        this.editEvent();
      }
    },
    getCorporateBranches() {
      this.axios.post(this.$loggedRole+"/get-corporate-branches",{
        city: this.city ? this.city.city : "",
      })
          .then(response => {
            this.corpBranches = response.data.data;
          })
          .catch(error => {
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    submitForm() {
      this.submitted = true;
        this.loading = true;
               this.axios
            .post(this.$loggedRole+"/event-sales", {
              restaurant_branch_id: JSON.stringify(this.restBranchID),
              services: JSON.stringify(this.taskArr),
              corporate_branch_id:JSON.stringify(this.corpBranchID),
              state:JSON.stringify(this.state),
              payment_mode:this.paymentMode,
              destination:this.destination,
              service_date:this.daterange,
              order_no:this.orderNo,
              country: this.country ? this.country.country : "",
              city: this.city ? this.city.city : "",
              sezSupply:this.sezSupply,
              saleFor:this.saleFor,
            })
            .then(result => {
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                title:'Event Sale'
              });
              this.resetForm();
            })

            .catch(error => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error"
              });
            });

    },
    updateForm() {
      if(sessionStorage.getItem('event') == this.event_sales_id){
        this.loading = true;
        this.axios
            .post(this.$loggedRole+"/event-sales/"+this.event_sales_id, {
              _method:'PUT',
              restaurant_branch_id: JSON.stringify(this.restBranchID),
              services: JSON.stringify(this.taskArr),
              corporate_branch_id:JSON.stringify(this.corpBranchID),
              state:JSON.stringify(this.state),
              payment_mode:this.paymentMode,
              destination:this.destination,
              service_date:this.daterange,
              order_no:this.orderNo,
              country: this.country ? this.country.country : "",
              city: this.city ? this.city.city : "",
              sezSupply:this.sezSupply,
              saleFor:this.saleFor,
            })
            .then(result => {
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success"
              });

            })
            .catch(error => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error"
              });
            });
      }



    },

    getAllRestaurantBranches() {
      this.axios
          .post(this.$loggedRole+"/get-restaurant-branches",{
            city: this.city ? this.city.city : "",
          })
          .then(result => {
            this.restBranchArr = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    resetForm(){
  this.restBranchID="";
  this.taskArr= [
    {
      service_name: "",
      sac: "",
      gstRate: 0,
      igstRate: 0,
      quantity:0,
      rate:0,
      unit:"",
      amount:0,
    }
  ];
  this.corpBranchID="";
  this.state="";
  this.paymentMode="";
  this.destination="";
  this.daterange="";
  this.orderNo="";
  this.country="";
  this.city="";
  this.sezSupply="";
},
    AddItem() {
      this.taskArr.push({
        eventItemId:"",
        service_name: "",
        sac: "",
        gstRate: 0,
        igstRate: 0,
        quantity:0,
        rate:0,
        unit:"",
        amount:0,
      });
    },
    deleteItem(index, eventItemId) {
      if (eventItemId) {
        this.loading = true;
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            this.axios
                .delete(this.$loggedRole+"/event-sales/"+eventItemId)
                .then(result => {
                  this.loading = false;
                  this.$swal({
                    title: "Deleted!",
                    text: result.data.message,
                    icon: "success"
                  });
                  this.taskArr.splice(index, 1);
                })
                .catch(error => {
                  this.loading = false;
                  this.$swal({
                    title: "Oops...",
                    text: error.response.data.message,
                    icon: "error"
                  });
                });
          }
          else{
            this.loading=false;
          }
        });
      } else {
        this.taskArr.splice(index, 1);
      }
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
          .post(this.$loggedRole+"/getAllCountry", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.countryArr = response.data.data;
            // this.country = (this.countryArr) ? this.countryArr[0] : "";
            // this.getAllCities();
          });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.cityArr = response.data.data;
          });
    },
    getStates(){
      this.loading = true;
      this.axios
          .get(this.$loggedRole+"/get-states")
          .then(result => {
            this.loading = false;
            this.stateArr = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    getGSTSlabs(){
      this.loading = true;
      this.axios
          .get(this.$loggedRole+"/get-gst-slabs")
          .then(result => {
            this.loading = false;
            this.gstRateArr = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    getIGSTSlabs(){
      this.loading = true;
      this.axios
          .get(this.$loggedRole+"/get-igst-slabs")
          .then(result => {
            this.loading = false;
            this.igstRateArr = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    setSacCode(index,dropDownType){
      if(dropDownType==="gst"){
        const value = this.taskArr[index].gstRate.hsn_sac_code;
        this.$set(this.taskArr[index], "sac", `${value}`);
        this.$set(this.taskArr[index], "igstRate", ``);
      }
      if(dropDownType==="igst"){
        const value = this.taskArr[index].igstRate.hsn_sac_code;
        this.$set(this.taskArr[index], "sac", `${value}`);
        this.$set(this.taskArr[index], "gstRate", ``);
      }
    },
    calculateAmount(index){
      const quantity = this.taskArr[index].quantity;
      const rate= this.taskArr[index].rate;
      const amount= quantity * rate;
      this.$set(this.taskArr[index], "amount", `${amount}`);
    },
    editEvent(){
      if(sessionStorage.getItem('event')){
        this.loading = true;
        this.axios
            .get(this.$loggedRole+"/event-sales/"+sessionStorage.getItem('event')+"/edit")
            .then((response) => {
              this.loading = false;
              this.isEdit=true;
              this.event_sales_id=response.data.data.id;
            this.state= response.data.data.state_request;
              this.restBranchID=response.data.data.restaurant_request;
              this.corpBranchID=response.data.data.corporate_request;
              this.country = response.data.data.country;
              if(this.country){
                this.getAllCities();
              }
              this.paymentMode = response.data.data.payment_mode;
              this.sezSupply= response.data.data.sez_supply;
              this.saleFor =response.data.data.sale_for;
              this.orderNo =response.data.data.buyer_order_no;
              this.destination=response.data.data.destination;
              this.daterange= response.data.data.service_date;
              this.city = response.data.data.city;
              if(this.city){
                this.getCorporateBranches();
                this.getAllRestaurantBranches();
              }
              this.taskArr = response.data.data.items;
            })
            .catch(error => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error"
              });
            });
      }
    }
  },
}
</script>
<style scoped></style>